import * as React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import styles from './layout.module.css';
//hooks
import useHeaderContact from 'hooks/useHeaderContact';

interface Props {
  text?: string;
  id: string;
  onCtaClick?: () => void;
}

function LinksContainer({ text = 'Contact us', id, onCtaClick }: Props) {
  const contact = useHeaderContact();
  return (
    <div className={text.length > 15 ? styles.btnBox : styles.btnGroup}>
      {onCtaClick ? (
        <button id={id} className={clsx('btn btn_60 btn__white', styles.btn)} onClick={onCtaClick}>
          {text}
        </button>
      ) : (
        <Link id={id} className={clsx('btn btn_60 btn__white', styles.btn)} to="/get-in-touch/">
          {text}
        </Link>
      )}
      <a
        className="btnCall"
        href={`${contact.calendly_link}?utm_source=brocoders&utm_medium=header&utm_campaign=calendly_link&hide_gdpr_banner=1`}
      >
        {contact.link_name}
      </a>
    </div>
  );
}

export default LinksContainer;
