import * as React from 'react';
import clsx from 'clsx';
//styles
import styles from '../technologies.module.css';
//components
import Booking from 'components/feedback';
import Bullets from 'components/bullets';
import GetConsultation from 'components/free-consultation';
import Recognition from 'components/recognition';
import Tabs from 'components/tabs';
import Layout from '../components/layout';
import Blog from 'components/blog/widget';
import { BroCoders } from 'components/carousel';
import FAQ from 'components/faq';
//meta
import identificators from 'components/googleAnalyticsIdentificators';
import { meta } from '../../../metaData';
//hooks
import { useFaqData } from 'hooks/useFaqData';

const LIST = [
  {
    title: 'Fascinating development speed',
    text: 'Powered by React and GraphQL features, Gatsby allows for building websites in less than no time. <span>A component-based model makes it possible to reuse existing elements within the website’s infrastructure</span>, which, in turn, enhances the development speed and reduces time-to-market. Besides, configuration flexibility ensures easy updating, thus keeping a project under a fixed budget.',
  },
  {
    title: 'Exceptional performance',
    text: 'Gatsby.js solutions are some of the fastest ones since Gatsby’s ecosystem is empowered with up-to-date web standards and top-notch technologies, such as React.js, WebPack, GraphQL, JavaScript, CSS and ES6+. <span>Gatsby.js prefetches resources for other pages once a website is loaded to ensure quick and stable work</span>. Gatsby.js also provides improved SEO algorithms thanks to a decent server-side rendering.',
  },
  {
    title: 'Strong security',
    text: 'Gatsby.js shields you from numerous data breach vulnerabilities since it’s separated from the CMS. You can publish static files with no fear of hacker attacks as there is <span>no direct connection to the database, dependencies, user info or any other sensitive data</span>.',
  },
];

const TABS_TITLES = [
  {
    title: 'Wesbite for company or agency',
  },
  {
    title: 'Blogging website',
  },
  {
    title: 'Basic e-commerce website',
  },
];

interface Props {
  pathContext: {
    prePost: ReadonlyArray<any>;
  };
  location: Location;
}

function GatsbyJS({ pathContext: { prePost }, location }: Props) {
  const faqData = useFaqData('allStrapiTechnology', 'Gatsby.js');

  return (
    <Layout
      title="Gatsby.js Development Services"
      subtitle="We synergize a static PWA generator with our multi-year development expertise to create lightning fast, future-proof and SEO-friendly web solutions."
      linkText="Estimate your project"
      metaData={{ main: meta.gatsby, faq: faqData?.list }}
      pathname={location.pathname}
      linkId={identificators.ESTIMATE_YOUR_PROJECT_HEADER}
    >
      <section className={clsx('section', 'gray-wave')}>
        <div className="inner">
          <h2 className="title">Why is Gatsby.js a beneficial option?</h2>
          <p className="subtitle">
            We offer our fast Gatsby-driven development to those clients who require an immediate
            solution at their hands
          </p>
          <Bullets className={styles.bullets} list={LIST} />
        </div>
      </section>
      <section className="section">
        <div className="inner">
          <h2 className="title">Our Gatsby-driven expertise</h2>
          <p className="subtitle">
            We work closely with you to design and develop a website from scratch or reshape your
            existing solution with a robust Gatsby.js open-source framework.
          </p>
          <Tabs list={TABS_TITLES} isArrow btnClassName="tabs__btn_30">
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  Gatsby can save your resources and make the development process extremely fast
                  thanks to a reusable components feature. Based on Gatsby.js, your website can cope
                  with greater capacities and provide a blazing-fast elements loading, resulting in
                  delightful customer experience as of higher conversions rate and reduced bounce
                  rate. In fact, our site is built on Gatsby.js as well, so you can go through a
                  working example to “touch” and evaluate the advantages of this framework.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  To enable server-side rendering and fast loading speeds, Gatsby.js utilizes static
                  files, which ensures a quick image loading, greater optimization and SEO-friendly
                  algorithms. With Gatsby, your website architecture is simplified and acts as a
                  single-page app, which makes it more efficient and flexible, resulting in
                  increased user experience.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
            <li className="tabs__item">
              <div className="tabs__column_first">
                <p>
                  When it comes to the e-commerce sector, there is one crucial thing to remember ─ a
                  slow website can significantly hurt sales. That said, Gatsby is the preferable
                  static site generator for building e-commerce websites as it can provide
                  remarkable security, lightning-fast performance and cost-efficient hosting of
                  static sites. Besides, with Gatsby.js, you can pre-build pages and send them into
                  a global cloud of servers to reach your clients as fast as possible.
                </p>
              </div>
              <div className="tabs__column_last" />
            </li>
          </Tabs>
        </div>
      </section>
      <section className={styles.carousel}>
        <BroCoders />
      </section>
      <section className="section">
        <div className="inner">
          <h2 className="title">Recognition</h2>
          <Recognition />
        </div>
      </section>
      <GetConsultation
        text="Win your end-users’ trust and loyalty with Gatsby client-oriented solutions"
        title="Hire our Gatsby.js development team to handle your project"
        linkId={identificators.ESTIMATE_A_PROJECT_BANNER}
      />
      <section className="section">
        <div className="inner">
          <FAQ data={faqData} />
          <Blog posts={prePost} />
        </div>
      </section>
      <Booking />
    </Layout>
  );
}

export default GatsbyJS;
