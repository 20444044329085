import { graphql, useStaticQuery } from 'gatsby';

export const useFaqData = (category: string, pageName: string) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiService {
        edges {
          node {
            title
            faq {
              title
              list {
                question
                answer
                id
              }
            }
          }
        }
      }
      allStrapiIndustry {
        edges {
          node {
            title
            faq {
              title
              list {
                question
                answer
                id
              }
            }
          }
        }
      }
      allStrapiTechnology {
        edges {
          node {
            title
            faq {
              title
              list {
                question
                answer
                id
              }
            }
          }
        }
      }
    }
  `);

  return data[category].edges.find((item) => item.node.title === pageName)?.node?.faq;
};
