import * as React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import styles from './layout.module.css';
//components
import Layout, { IMetaData } from 'components/layout';
import Header from './header';
import Footer from 'components/layout/footer';
import SubNavigation from 'components/layout/header/subnav';
import LinksContainer from './linksContainer';
//hooks
import useHeaderContact from 'hooks/useHeaderContact';

const Routes = require('../../routes').Routes;

const subNav = [
  {
    title: 'Node.js',
    to: Routes.TECHNOLOGIES_NODEJS,
  },
  {
    title: 'React.js',
    to: Routes.TECHNOLOGIES_REACTJS,
  },
  {
    title: 'React Native',
    to: Routes.TECHNOLOGIES_REACT_NATIVE_JS,
  },
  {
    title: 'Gatsby.js',
    to: Routes.TECHNOLOGIES_GATSBY,
  },
  {
    title: 'Blockchain',
    to: Routes.TECHNOLOGIES_BLOCKCHAIN,
  },
];

interface Props {
  title: string;
  subtitle: string;
  linkText?: string;
  children: React.ReactNode;
  metaData: IMetaData;
  pathname: string;
  linkId: string;
  linkTo?: string;
  image?: React.ReactNode;
  customLink?: React.ReactNode;
  hasLinks?: boolean;
  bcgColor?: string;
}

function Main({
  title,
  subtitle,
  children,
  linkText,
  metaData,
  pathname,
  linkId,
  image,
  customLink,
  hasLinks = true,
  bcgColor = 'var(--nav-invers-color)',
}: Props) {
  return (
    <Layout title={title} navBtnTextColor="#05ACCD" metaData={metaData}>
      <Header
        subNavigation={() => (
          <SubNavigation pathname={pathname} list={subNav} className={styles.subnav} />
        )}
        bcgColor={bcgColor}
      >
        <h1 className="header-title">{title}</h1>
        <p className="header-text">{subtitle}</p>
        {image}
        {customLink}
        {hasLinks && <LinksContainer text={linkText} id={linkId} />}
      </Header>
      <main className={clsx('main', styles.main)}>{children}</main>
      <Footer />
    </Layout>
  );
}

export default Main;
