import React from 'react';
import clsx from 'clsx';
import styles from './faq.module.css';
import FAQItem from './faq-item';

interface IFaqListItem {
  question: string;
  answer?: string;
  renderAnswer?: () => React.ReactNode;
  id?: number;
}

export interface IFaq {
  list: Array<IFaqListItem>;
  title?: string;
}

interface IProps {
  data?: IFaq;
}

const FAQ = ({ data = { title: 'FAQ', list: [] } }: IProps) => {
  if (!data?.list?.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      <h4 className={clsx(styles.title, 'title')}>{data.title || 'FAQ'}</h4>
      <div className={styles.list}>
        {data.list.map((item, idx) => (
          <FAQItem {...item} key={idx} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
