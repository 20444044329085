import * as React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Tags from '../tags';
import { getDate } from '../helpers';
import { ImageContainer, Tag } from '../Types';
import styles from './widget.module.css';

const Routes = require('../../routes').Routes;

interface PostItem {
  id: string;
  main_photo: ImageContainer;
  tags: ReadonlyArray<Tag>;
  date: string;
  title: string;
  content: string;
  slug: string;
}

interface Props {
  posts: ReadonlyArray<PostItem>;
}

function BlogWidget({ posts }: Props) {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Read more on our blog</h3>
      <ul className={styles.list}>
        {posts.map((e) => (
          <li className={styles.item} key={e.id}>
            <div className={styles.post}>
              <Link
                to={`${Routes.BLOG}${e.slug}/`}
                className={styles.link}
                aria-label="Go to blog page"
              >
                <BackgroundImage fluid={e.main_photo.childImageSharp.fluid} className={styles.bg} />
              </Link>
              <div className={styles.date}>{getDate(e.date)}</div>
              <Link to={`${Routes.BLOG}${e.slug}/`} className={styles.link}>
                <h4 className={styles.postTitle}>{e.title}</h4>
              </Link>
              <Tags list={e.tags} />
              <p className={styles.text}>{e.content.replace(/(###|##|#|\*\*|\*)/, '') + '...'}</p>
            </div>
          </li>
        ))}
      </ul>
      <div className={styles.btnGroup}>
        <Link to={Routes.BLOG} className={clsx('btn btn_60 btn__black btn__black_red', styles.btn)}>
          view all news
        </Link>
      </div>
    </div>
  );
}

export default BlogWidget;
