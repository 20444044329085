import * as React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import styles from './get.module.css';

interface Props {
  title: string;
  text?: string;
  linkId?: string;
  containerClassName?: string;
  to?: string;
  linkText?: string;
  onClick?: () => void;
}

function GetConsultation({
  text,
  title,
  containerClassName,
  to = '/get-in-touch/',
  linkText = 'Estimate a project',
  onClick,
  linkId,
}: Props) {
  return (
    <section className={clsx(styles.container, containerClassName)}>
      <div className="inner">
        <p className={styles.text}>{text}</p>
        <h2 className={clsx(styles.title)}>{title}</h2>
        {onClick ? (
          <button
            type="button"
            onClick={onClick}
            className={clsx('btn btn_60 btn__white', styles.btn)}
          >
            {linkText}
          </button>
        ) : (
          <Link id={linkId} to={to} className={clsx('btn btn_60 btn__white', styles.btn)}>
            {linkText}
          </Link>
        )}
      </div>
    </section>
  );
}

export default GetConsultation;
