import React, { memo, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
// styles
import styles from './faq.module.css';
import clsx from 'clsx';
// assets
import Icon from './icon.inline.svg';

interface IProps {
  question: string;
  answer?: string;
  renderAnswer?: () => React.ReactNode;
}

const FAQItem = ({ question, answer, renderAnswer }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggleQuestion = () => setIsOpen(!isOpen);

  const answerView = useMemo(() => {
    if (renderAnswer) {
      return <div className={styles.answer}>{renderAnswer()}</div>;
    }

    if (answer) {
      return <ReactMarkdown className={styles.answer} children={answer} skipHtml={false} />;
    }

    return null;
  }, []);

  return (
    <div className={styles.item}>
      <div className={clsx(styles.question, { [styles.open]: isOpen })}>
        <div className={styles.icon} onClick={onToggleQuestion}>
          <Icon />
        </div>
        <div onClick={onToggleQuestion} className={styles.text}>
          {question}
        </div>
      </div>
      {isOpen && answerView}
    </div>
  );
};

export default memo(FAQItem);
